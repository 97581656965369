.bars{
    display: none;
    z-index: 2;
}
.menu{
    display: none;
}


@media(max-width:980px){



.bars{
    display: inline;
    cursor: pointer;
    z-index: 3;
}
.menu{
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    background-color: #000;
    width: 100%;
    height: 120%;
    transform: translateY(0);
    opacity: 0;
    visibility: hidden;
    left: 0;
    top: 0;
    transition: all linear 0.4s;
    z-index: 2;

}
.menu-active{
    visibility: visible;
    opacity: 1;
    transform: translateY(0);


    /* height: 400px; */
}
.nav_mob nav{
width: 100%;

}
.list-menu{
    display: flex;
    align-items: center;
    width: 100%;
    visibility: hidden;
    padding-bottom: 50px;
    opacity: 0;
    flex-direction: column;
    margin-bottom: 20px;
    line-height: 60px;
    transition: all linear 0.5s;
    transform: scale(0.6);
}

.list-menu-active{
    opacity: 1;
    visibility: visible;
    transform: scale(1);

}
.arrowdown{
position: relative;
top: 4px;
bottom: 0;
left: 0;
right: 0;


}
.arrowup{
position: relative;
top: 4px;
bottom: 0;
left: 0;
right: 0;
}

.reservas{
    visibility: hidden;
    opacity: 0;
    text-align: center;
    line-height: 60px;
    transform: scale(0.5);
    transition: all linear 0.2s;

}

.reservas-active{
    visibility: visible;
    transform: scale(1);
    opacity: 1;
}
.visibleFalse{
    visibility: hidden;
    opacity: 0;
}

}

@media(max-height:500px){

    .list-menu{
        padding-bottom: 0px;
        line-height: 35px;


    }


}