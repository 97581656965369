.footer{
    background-color: rgb(20, 20, 20 );
    font-family: roboto;
}

.container-footer{
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    padding: 4% 0 4% 0;
    flex-wrap: wrap;
}
.logo{
    width: 120px;
}
.img_social{
    
    width: 50px;
    padding: 4%;
    border: 1px white solid;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 8px;
    transition: all linear 0.1s;
}
.img_social:hover{
    background-color: rgba(255, 255, 255, 0.222);
}
.info{
    display: flex;
    flex-direction: column;
    color: #fff;
    line-height: 30px;
}
.info h1{
    color: rgb(214,161,67);
    padding-bottom: 6%;

}
.info a{
    text-decoration: none;
    color: #fff;
    transition: all linear 0.1s;
}
.info a:hover{
    color: rgb(214,161,67);

}
.horario p{
    font-size: 19px;
}

.horario ul{
    list-style: none;
    font-size: 13px;
    line-height: 25px;
}

.copy{
    color: #fff;
    text-align: center;
}

@media(max-width:738px){
    .container-footer{
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 95%;
        margin: 0 auto;
    }
    .info{
        margin-top: 4%;
    }
}
