.container_background{
    background-image: url(../images/foto-background-cardapio.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 90vh;
    font-family: 'Roboto', sans-serif;
}

.escrita_background{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 60%;
    height: 100%;
    text-align: center;
    color: white;
}

.escrita_background h1{
    font-size: 60px;
    margin-bottom: 6vh;
}
.escrita_background p {
   font-size: 20px;
   font-weight: 1000;
}
.main{
    background-color: rgb(30,30,30);
}
.container_sobre{
    display: flex;
     justify-content: space-around;
     width: 95%;
     text-align: justify;
     margin: 3% auto 0 4%}

.list{
    line-height: 22px;
    width: 20%;
    font-family: roboto;
    padding-top: 3%;

}     

.list h1{
    color: rgb(214,161,67);
    padding-bottom: 5%;

}
.list ul{
    color: #fff;
    margin-left: 0%;
    
}


    


@media(max-width: 899px){
    .escrita_background{
        width: 90%;
    }
   
    .escrita_background h1{
        font-size: 50px;
    }
    .container_sobre{
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        padding: 9% 0;
    }
    .list{
        width: 90%;
        text-align: center;
    }
    .list ul{
        list-style: none;
        margin-left: 0;
    }

}