
    
    .container_secundario{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 8%;
    }
    
    .container_img{
        width: 300px;
        height: 300px;
    }
    
    .img{
        border-radius: 50%;
        width:100%;
        height: 100%;
        box-shadow: 30px 30px 35px rgba(0, 0, 0, 0.747);
        transition: all linear 0.2s;
    }
    .img:hover{
        transform: scale(1.05);
    }
    
    .container_cardapio{
        min-width: 300px;
        padding-left: 5%;
        font-family: roboto;
    
    }
    .container_cardapio h1{
        color: rgb(214,161,67);
        margin-bottom: 7%;
    }
    
    .container_cardapio ul{
        list-style: none;
        color: #fff;
        line-height: 30px;
        font-size: 16px;
    }
    @media(max-width: 612px){

        .container_cardapio{
            text-align: center;
            padding: 5% 0 5% 0;

        }
        .container_cardapio h1{
            margin-top: 3%;
        }
    }