.container{
    display: flex;
    flex-wrap: wrap;
    width: 95%;
    margin: 0% 5%;
    padding: 70px 0 70px 0;
    font-family: roboto;

}
.container_img{
    width: 350px;
    z-index: 1;
}
.foto{
    width:100%;
    height: 20%;
    object-fit: cover;
    border-radius: 10px;
    filter: grayscale(30%);
    cursor: pointer;
    box-shadow: 20px 20px 35px rgba(0, 0, 0, 0.747);

    transition: all linear 0.1s;
}
.foto:hover{
    filter: grayscale(0%);
    transform: scale(1.05);

}
.container_paragrafo{
    text-align: justify;
    color: #fff;
    margin: 7vh 0 0 4vw;
    padding-bottom: 5%;
    width: 60%;
} 

.nome_Loja{
    font-size: 26px;
    color: rgb(214,161,67);

}


@media(max-width: 899px){
    .container{
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
        padding: 0 0 100px 0;
        margin: 0 auto;
    }
    .container_paragrafo{
        width: 90%;
        margin: 3vh 0 3% 0;
    }
    .foto{
        box-shadow: none

    }
}
    