.main-cardapio{
    padding: 10% 0
}
.container_background_cardapio{
    background-image: url(../images/espetos-1.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 90vh;
    font-family: 'Roboto', sans-serif;
}

.escrita_background_cardapio{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 60%;
    height: 100%;
    text-align: center;
    color: white;
}
.escrita_background_cardapio h1{
    font-size: 60px;
    margin-bottom: 6vh;
}

.container_principal{
    margin: 0 1% 0 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}
.container_btn{
text-align: center;
}
.btn{
    padding: 1% 2%;
    border-radius: 10px;
    border: 2px solid black;
    cursor: pointer;
    background-color: rgb(214,161,67);;
}
.btn:hover{
background-color:rgb(180,131,47) ;}